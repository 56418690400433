<template>
  <v-container
    v-if="USER.userInfo.departments.length > 0"
    fluid
    fill-height
    height="80vh"
  >
    <v-card :dark="$dark.get()" width="100vw">
      <loader v-if="loading"></loader>
      <v-card-title>
        <div class="title ml-2 mb-2 pb-1">Список курьеров</div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            :class="[
              'pt-0',
              'pb-0',
              {
                'pr-0':
                  $vuetify.breakpoint.name === 'lg' ||
                  $vuetify.breakpoint.name === 'xl',
              },
            ]"
          >
            <yandex-map
              :zoom="map.zoom"
              :settings="map_settings"
              :coords="map.coords"
              @boundschange="map_boundschange"
            >
              <ymap-marker
                v-for="(item, index) in curier_list"
                :key="'cur_' + index"
                :marker-id="'c_' + index"
                :coords="coords_translate(item)"
                :icon="{
                  content: item.courier.username,
                  coords: coords_translate(item),
                }"
                @click="active_curier_info = item.courier.id"
              />
              <ymap-marker
                v-for="item in yandexCouriers"
                :key="'yandex-cur_' + item.claimId"
                :marker-id="'yandex-c_' + item.claimId"
                :coords="[item.lat, item.lon]"
                :icon="{
                  content: `Yandex-курьер №${item.claimId}`,
                  coords: [item.lat, item.lon],
                  color: 'red',
                }"
              />
            </yandex-map>
          </v-col>
          <v-col cols="12" lg="5" xl="4">
            <div class="map_curier_table__wrapper">
              <p
                v-if="count_active_all_order === 0"
                class="font-weight-medium ml-2"
              >
                В данный момент у курьеров нет заказов
              </p>
              <div v-for="(item, index) in curier_list" :key="'sCur_' + index">
                <v-row
                  v-if="
                    curier_orders[item.courier.id] &&
                    curier_orders[item.courier.id].length > 0
                  "
                  :class="[
                    'text-left ma-2 mt-0 mb-1 pl-2 c_list',
                    { active_c_list: active_curier_info === item.courier.id },
                  ]"
                  style="border: 1px solid; border-color: rgba(0, 0, 0, 0.15)"
                  @click="
                    loading = true;
                    active_curier_info = item.courier.id;
                    loading = false;
                  "
                >
                  <v-col cols="8" class="pt-1 pb-1">
                    <span>{{ item.courier.username }}</span>
                  </v-col>
                  <v-col cols="4" class="pt-1 pb-1 d-flex justify-end">
                    <v-avatar color="#3A9FC9" size="26" right>
                      <span class="white--text body-1">{{
                        curier_orders[item.courier.id].filter(
                          get_status("Отгружен")
                        ).length
                      }}</span>
                    </v-avatar>
                    <v-avatar color="#d0ca02" size="26" right class="ml-2">
                      <span class="white--text body-1">{{
                        curier_orders[item.courier.id].filter(
                          get_status("Доставлен")
                        ).length
                      }}</span>
                    </v-avatar>
                    <v-avatar color="error" size="26" right class="ml-2">
                      <span class="white--text body-1">{{
                        curier_orders[item.courier.id].filter(
                          get_status("Доставляется")
                        ).length
                      }}</span>
                    </v-avatar>
                  </v-col>
                </v-row>
              </div>
            </div>
            <v-simple-table
              v-if="active_curier_info > 0"
              height="40vh"
              fixed-header
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">№</th>
                    <th class="text-left">Адрес</th>
                    <th class="text-left">Статус</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in curier_orders[active_curier_info]"
                    :key="'order_' + index"
                  >
                    <td>{{ item.orderId }}</td>
                    <td>{{ item.address_full }}</td>
                    <td>
                      <v-btn
                        :to="`/order/edit/${item.orderId}`"
                        target="_blank"
                        small
                        :color="item.status_info.color"
                        >{{ item.status_info.title }}</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "../components/Loader";
import Stomp from "webstomp-client";
import axios from "axios"; 

export default {
  components: {
    Loader,
  },
  data() {
    return {
      map: {
        zoom: 12,
        coords: [49.807754, 73.088504], // координаты по умолчанию для города 1
        controls: [],
      },
      loading: false,
      dialog: false,
      map_settings: {
        apiKey: "4ca540e5-4dd9-43dd-b029-5d4a1097eec8",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      active_curier_info: -1,
      count_active_all_order: 0,
      curier_orders: [],
      curier_list: [],
      order_count: 0,
      status_list: [""],
      stomp: null,
      stompClient: null,
      yandexCouriers: [],
    };
  },
  computed: {
    ...mapGetters({
      EMPLOYEE: "Employee/STATE",
      STATUS: "Status/STATE",
      USER: "User/STATE",
    }),
  },
  async created() {
    this.loading = true;
    await this.checkPower();
    this.changeCity(this.$root.city);
    await this.getStatusList();
    let all_status = this.STATUS.allStatus;
    for (let i = 0; i < all_status.length; i++) {
      this.status_list[all_status[i].id] = all_status[i].name;
    }
    await this.update_courier();
    this.loading = false;
  },
  mounted() {
    this.connect();
  },
  methods: {
    ...mapActions({
      getStatusList: "Status/GET_ALL_STATUS",
      getAllCourier: "Employee/GET_COURIER_LIST_MAP",
      getCourier: "Employee/GET_COURIER",
      getAllCourierOrder: "Employee/GET_COURIER_LIST_MAP_ORDER",
      getAllPicker: "Employee/GET_PICKER_LIST_MAP", // Если нужно получать сборщиков
    }),
    checkPower() {
      let state = this.$power.check(this.$route.meta.power);
      if (state === false) {
        this.$router.push("/");
      }
    },
    map_boundschange(e) {
      this.map.zoom = e._cache.newZoom;
      this.map.coords = e._cache.newCenter;
    },
    get_status(name) {
      return (status) => status.status_info.title === name;
    },
    async update_courier() {
      const colors = [
        "#929594",
        "#d0ca02",
        "#e2ac54",
        "#85C5E0",
        "#df8956",
        "#686a69",
        "#3A9FC9",
        "#fbff00",
        "#249641",
        "#929594",
      ];

      try {
        // Запрос для получения курьеров
        const response = await axios.post('/api/admin/courier/all/city/', {
  cityId: this.$root.city,
  departmentId: [],
});


        const curierMap = response.data;
        this.curier_list = curierMap.filter((x) => {
          // Обработка курьеров по вашему усмотрению
        });

        // Получаем заказы для каждого курьера
        for (let i = 0; i < curierMap.length; i++) {
          const data = await this.getAllCourierOrder({ id: curierMap[i].courier.id });
          
          data.forEach((order) => {
            order.address_full = `${order.address.street || ''} ${order.address.house || ''} ${order.address.block || ''}`;
            order.status_info = {
              color: colors[order.statusId],
              title: this.status_list[order.statusId],
            };
            if (order.statusId < 8) {
              curierMap[i].order_actives_length++;
            }
            this.count_active_all_order++;
          });

          this.$set(this.curier_orders, curierMap[i].courier.id, data);
        }

      } catch (error) {
        console.error("Ошибка при обновлении курьеров:", error);
      }
    },

    async update_picker() {
      try {
        // Запрос для получения сборщиков
        const pickerResponse = await axios.post('/api/admin/picker/all/city/', {
          cityId: this.$root.city,
          departmentId: [],  
        });

        const pickerMap = pickerResponse.data;
        // Обработка сборщиков
      } catch (error) {
        console.error("Ошибка при обновлении сборщиков:", error);
      }
    },

    connect() {
      this.socket = new WebSocket(process.env.VUE_APP_WS_URL);
      this.stompClient = Stomp.over(this.socket);
      this.stompClient.connect({}, (frame) => {
        if (frame.command == "CONNECTED") {
          // подписка на местоположение курьеров аян
          this.stompClient.subscribe(
            `/admin/socket/courier/geo/2`,
            (dataSock) => {
              const data = JSON.parse(dataSock.body);
              const index = this.curier_list.map((x) => {
                return x.courier.id;
              });
              let cInd = index.indexOf(data.courier.courier.id);
              if (cInd !== -1) {
                this.curier_list[cInd].courierGeo = data.courier.courierGeo;
                this.$forceUpdate();
              }
            }
          );

          // подписка на местоположение яндекс курьеров
          this.stompClient.subscribe(
            `/admin/socket/courier/yandex/geo`,
            (dataSock) => {
              const data = JSON.parse(dataSock.body);
              const indexArr = this.yandexCouriers.map((x) => {
                return x.claimId;
              });

              const courierIndex = indexArr.indexOf(data.claimId);
              if (courierIndex !== -1) {
                this.yandexCouriers[courierIndex] = data;
                this.$forceUpdate();
              } else {
                this.yandexCouriers.push(data);
              }
            }
          );
        }
      });
    },

    coords_translate(item) {
      if (item.courierGeo != null) {
        let arrs = item.courierGeo.coords.split(",");
        return [parseFloat(arrs[0]), parseFloat(arrs[1])];
      }
      return [0, 0];
    },

    changeCity(city) {
      let iterator = 0;
      const new_zoom = setInterval(() => {
        this.map.zoom = 12;
        if (city == 1) this.map.coords = [49.807754, 73.088504];
        if (city == 2) this.map.coords = [50.056413, 72.955194];
        if (city == 3) this.map.coords = [51.146034, 71.452748];

        if (iterator == 2) {
          clearInterval(new_zoom);
        }
        iterator++;
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.ymap-container {
  height: 100%;
  height: 80vh;
  @media screen and (max-width: 600px) {
    height: 40vh !important;
  }
}
.c_list {
  &:hover {
    cursor: pointer;
  }
}
.ymaps-2-1-78-controls__control_toolbar {
  display: none !important;
}
.map_curier_table__wrapper {
  height: 80vh;
  overflow-y: auto;
  @media screen and (max-width: 600px) {
    height: 35vh;
  }
}
</style>
